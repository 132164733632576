<template>
  <patient-profile-find
    title="Find Patient Profile"
  ></patient-profile-find>
</template>

<script>
import PatientProfileFind from '@/components/patients/Find.vue'

export default {
  components: { PatientProfileFind },
}
</script>
